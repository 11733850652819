import Controller from '@ember/controller';
import config from '../config/environment';

const DOMAINS_PRICE_TMP = [
  {
    tld: 'cz',
    defaultPrice: 199,
    defaultRenewalPrice: 299,
    defaultTransferPrice: 0
  },
  {
    tld: 'com',
    defaultPrice: 335,
    defaultRenewalPrice: 335,
    defaultTransferPrice: 335
  },
  {
    tld: 'eu',
    defaultPrice: 49,
    defaultRenewalPrice: 239,
    defaultTransferPrice: 239
  },
  {
    tld: 'sk',
    defaultPrice: 339,
    defaultRenewalPrice: 389,
    defaultTransferPrice: 389
  },
  {
    tld: 'net',
    defaultPrice: 319,
    defaultRenewalPrice: 389,
    defaultTransferPrice: 389
  },
  {
    tld: 'org',
    defaultPrice: 299,
    defaultRenewalPrice: 389,
    defaultTransferPrice: 389
  }
];

export default Controller.extend({
  config,
  filteredDomains: DOMAINS_PRICE_TMP
});
