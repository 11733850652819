import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['landing-page__block', 'landing-page__hero'],
  slugKey: computed('datoSlug', function () {
    return this.datoSlug + '.' + this.datoSlug;
  }),
  topOffset: true
});
