import Service, { inject as service } from '@ember/service';

import ENV from 'domena-mobile/config/environment';
import Logger from 'domena-mobile/utils/logger';
import fetchAjax from '../utils/ajax';
import { resolve } from 'rsvp';

const LIMIT = 100; // vetsi cislo jinak se vraci jen 10;
const ACTION_MODEL_NAME = 'OfferWies';
const ROUTE_SLUG_MAPPING = {};
const CMS_KEYS_BLACKLIST = ['_site', `all${ACTION_MODEL_NAME}`];
const CMS_FIELDS_SCHEME = `allPages(first: ${LIMIT}, locale: ${ENV.LOCALE}) { title slug _seoMetaTags { tag attributes content } blocks { ... on PageBlockRecord { slug title subtitle content primaryLink image { title alt width height url customData responsiveImage { srcSet webpSrcSet height width } } } ... on GalleryRecord { title slug images { title alt width height url customData responsiveImage { srcSet webpSrcSet height width } } } } }`;
const CMS_FALLBACK_SEO = `_site(locale: ${ENV.LOCALE}) { globalSeo { facebookPageUrl fallbackSeo { description title twitterCard image { url } } twitterAccount siteName } faviconMetaTags { attributes content tag } }`;
const OFFERS_FIELDS_SCHEME = `all${ACTION_MODEL_NAME}(first: ${LIMIT}, locale: ${ENV.LOCALE}) { from tld text service position years until originalPrice newPriceOverwrite }`;

export default Service.extend({
  fastboot: service(),
  store: service(),
  _seoTags: null,
  globalSeo: null,
  faviconMetaTags: null,

  loadOffers() {
    const { CMS_API_URL: url, CMS_API_AUTH_TOKEN: token } = ENV.cms;

    return fetchAjax(url, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      mode: 'cors',
      body: `{ "query": "query { ${OFFERS_FIELDS_SCHEME} }" }`
    }).then(response => {
      const { errors } = response;
      if (errors) {
        Logger.error(errors);
        return resolve({});
      }
      const { data } = response;
      return data;
    }, Logger.error);
  },
  loadOffersCache() {
    const path = this.get('fastboot.isFastBoot')
      ? `${this.get('fastboot.request.protocol')}//${this.get(
          'fastboot.request.host'
        )}/`
      : '/';
    return fetchAjax(`${path}offers.json`).then(response => {
      const { errors } = response;
      if (errors) {
        Logger.error(errors);
        return resolve({});
      }
      const { data } = response;
      return data;
    }, Logger.error);
  },
  processOffersResponse(data) {
    const offerData = (data[`all${ACTION_MODEL_NAME}`] || [])
      .filter(offer => Boolean(offer.originalPrice))
      .map(offer => ({
        type: 'offer',
        id: offer.tld,
        attributes: {
          ...offer,
          from: offer.from ? new Date(offer.from) : null,
          until: offer.until ? new Date(offer.until) : null
        }
      }));
    return this.store.push({ data: offerData });
  },
  processPagesResponse(data) {
    if (!data) {
      return {};
    }
    const modelNames = Object.keys(data);
    const translationData = {};
    const seoTagsData = {};
    if (data._site) {
      if (data._site.globalSeo) {
        this.set('globalSeo', data._site.globalSeo);
      }
      if (data._site.faviconMetaTags) {
        this.set(
          'faviconMetaTags',
          data._site.faviconMetaTags.map(item => {
            item.type = item.tag;
            item.attrs = item.attributes;
            delete item.tag;
            delete item.attributes;
            return item;
          })
        );
      }
    }

    this.set(
      'pages',
      data.allPages.reduce((out, item) => {
        out[item.slug] = item;
        return out;
      }, {})
    );

    modelNames
      .filter(modelName => !CMS_KEYS_BLACKLIST.includes(modelName))
      .forEach(modelName => {
        const modelData = data[modelName];
        modelData.forEach(modelRecord => {
          const slugName = modelRecord.slug;
          const modelKeys = Object.keys(modelRecord);
          modelKeys.forEach(key => {
            if (['_seoMetaTags'].includes(key)) {
              seoTagsData[slugName] = modelRecord[key]
                .map(item => {
                  item.type = item.tag;
                  item.attrs = item.attributes;
                  delete item.tag;
                  delete item.attributes;
                  if (item.type == 'meta' && item.attrs) {
                    if (item.attrs.property == 'og:locale') {
                      item.attrs.content = ENV.LOCALE;
                    }
                    if (item.attrs.property == 'og:type') {
                      item.attrs.content = 'website';
                    }
                    if (item.attrs.property == 'og:title') {
                      const content = [item.attrs.content];
                      const pageTitle =
                        this.globalSeo && this.globalSeo.siteName;
                      if (pageTitle) {
                        content.unshift(pageTitle);
                      }
                      item.attrs.content = content.join(' | ');
                    }
                  }
                  return item;
                })
                .filter(item => {
                  if (
                    item.type == 'meta' &&
                    item.attrs &&
                    (['twitter:description', 'twitter:title'].includes(
                      item.attrs.name
                    ) ||
                      ['article:modified_time', 'og:site_name'].includes(
                        item.attrs.property
                      ))
                  ) {
                    return false;
                  }
                  return true;
                });
            } else if (Array.isArray(modelRecord[key])) {
              modelRecord[key].forEach(item => {
                const itemSlug = item.slug;
                translationData[slugName][itemSlug] = item;
              });
            } else if (translationData[slugName]) {
              translationData[slugName][key] = modelRecord[key];
            } else {
              translationData[slugName] = { [key]: modelRecord[key] };
            }
          });
        });
      });
    this.set('_seoTags', seoTagsData);
    this.set('_translationData', translationData);
    return translationData;
  },
  getSeoTags(routeName, filterOutTitle = true) {
    const seoTags = this.get('_seoTags');
    const slug = ROUTE_SLUG_MAPPING[routeName] || routeName;
    if (!seoTags || !seoTags[slug]) {
      return null;
    }
    if (filterOutTitle) {
      return seoTags[slug].filter(tag => tag.type != 'title');
    } else {
      return seoTags[slug];
    }
  },
  getSeoTitle(routeName) {
    const seoTags = this.getSeoTags(routeName, false);
    if (!seoTags) {
      return null;
    }
    const titleTag = seoTags.find(tag => tag.type == 'title');
    return titleTag && titleTag.content;
  },
  loadAll() {
    const { CMS_API_URL: url, CMS_API_AUTH_TOKEN: token } = ENV.cms;

    return fetchAjax(url, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      mode: 'cors',
      body: `{ "query": "query { ${CMS_FALLBACK_SEO} ${CMS_FIELDS_SCHEME} }" }`
    }).then(response => {
      const { errors } = response;
      if (errors) {
        Logger.error(errors);
        return resolve({});
      }
      const { data } = response;
      return data;
    }, Logger.error);
  },
  loadAllCache() {
    const path = this.get('fastboot.isFastBoot')
      ? `${this.get('fastboot.request.protocol')}//${this.get(
          'fastboot.request.host'
        )}/`
      : '/';
    return fetchAjax(`${path}dato.json`).then(response => {
      const { errors } = response;
      if (errors) {
        Logger.error(errors);
        return resolve({});
      }
      const { data } = response;
      return data;
    }, Logger.error);
  }
});
