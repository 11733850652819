import { computed, observer } from '@ember/object';

import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import addDefaultParams from '../utils/default-wyapi-params';
import config from '../config/environment';

export default Component.extend({
  store: service(),
  cart: service(),
  taskQueue: service(),
  events: service(),
  currentUser: service(),
  intl: service(),
  modal: service(),
  classNames: ['domain-result-item'],
  classNameBindings: ['selected', 'stateClass', 'pinned', 'onlyActive'],
  attributeBindings: ['domainObject.displayName:title'],
  suggested: false,
  onlyRemove: false,
  price: computed(
    'hasOffer',
    'currentUserOffer.discountedPrice',
    'domainObject.totalPrice',
    function () {
      return this.hasOffer
        ? this.currentUserOffer.discountedPrice
        : this.domainObject.totalPrice;
    }
  ),
  premiumCheck: observer('domainObject.premium', function () {
    if (this.get('domainObject.premium')) {
      this._loadPremiumPrice();
    }
  }),
  pinned: false,
  onlyActive: false,
  name: '',
  searchName: '',
  states: null,
  source: 'domena',
  state: alias('domainObject.state'),
  stateClass: computed('state', function () {
    const state = this.get('state');
    return state && state.dasherize();
  }),
  cartParams() {
    return {
      name: this.get('name'),
      tld: this.get('domainObject.tldName'),
      years: this.get('hasOffer')
        ? parseInt(this.get('currentUserOffer.years'), 10)
        : null
    };
  },
  selected: computed('name', 'cart.count', function () {
    return Boolean(this.get('cart').inCart('registration', this.cartParams()));
  }),
  observer: observer('name', 'elemIsVisible', function () {
    this._domainCheck();
  }),
  init() {
    this._super(...arguments);
    this.set('states', {
      available: {
        icon: 'check',
        text: this.intl.t('domain_result.available')
      },
      taken: { icon: 'times', text: this.intl.t('domain_result.taken') },
      checking: {
        icon: 'spinner fa-spin fa-fw',
        text: this.intl.t('domain_result.checking')
      },
      initial: {
        icon: 'spinner fa-cog fa-fw',
        text: this.intl.t('domain_result.initial')
      },
      invalid: { icon: 'times', text: this.intl.t('domain_result.invalid') },
      withErrors: { icon: '', text: this.intl.t('domain_result.with_errors') }
    });
  },
  didInsertElement() {
    this.set('scrollHandler', () => {
      this._elemVisibleCheck();
    });

    this.set('checkHandler', () => {
      this._elemVisibleCheck(true);
    });

    this.get('events').on('resize', this.get('checkHandler'));

    this.get('events').on('scroll:change', this.get('scrollHandler'));
    this._elemVisibleCheck();
  },
  willDestroyElement() {
    this.get('events').off('resize', this.get('checkHandler'));
    this.get('events').off('scroll:change', this.get('scrollHandler'));
  },
  currentUserOffer: computed(
    'domainObject.{activeOffer,priceValue}',
    function () {
      const activeOffers = this.get('domainObject.activeOffer');
      if (!activeOffers) {
        return;
      }

      const newPrice = parseFloat(
        this.domainObject && this.get('domainObject.priceValue')
      );

      const minRegistrationYears = parseInt(this.get('domainObject.price.minRegistrationYears') || "1", 10);

      return {
        price: activeOffers.originalPrice,
        discountedPrice: newPrice / minRegistrationYears,
        years: activeOffers.get('years'),
        yearsString: activeOffers.get('years').toString(),
        tld: activeOffers.get('tld'),
        text: activeOffers.get('text'),
        service: activeOffers.get('service')
      };
    }
  ),
  // item has offer
  hasOffer: computed('currentUserOffer', 'state', function () {
    const isOffer = !!this.get('currentUserOffer');
    const isAvailable = this.get('state') === 'available';
    const yearsCondition =
      this.get('domainObject.years') === this.get('currentUserOffer.years');
    const isDomainCheck =
      this.get('domainObject').constructor.modelName === 'domain-check';
    return isOffer && isAvailable && (isDomainCheck ? true : yearsCondition);
  }),
  // item html classes
  itemClasses: computed(
    'domainObject.{type,years,premium}',
    'hasOffer',
    'currentUserOffer.{text,years}',
    function () {
      let classes = [];
      classes.push(this.get('domainObject.type'));
      if (this.get('hasOffer')) {
        classes.push('has-offer');
      }
      if (this.get('domainObject.premium')) {
        classes.push('is-premium');
      }
      if (this.get('domainObject.years') > 1) {
        classes.push('has-years');
      }
      if (isPresent(this.get('currentUserOffer.text'))) {
        classes.push('has-offer-text');
      }
      if (this.get('currentUserOffer.years') > 1) {
        classes.push('has-offer-years');
      }
      return classes.join(' ');
    }
  ),
  _elemVisibleCheck(disableOffset = false) {
    this.set(
      'elemIsVisible',
      this.get('events').isVisible(this.$(), true, disableOffset ? 0 : 150)
    );
  },
  _domainCheck() {
    const name = this.get('name');
    if (!name) {
      return;
    }
    const domain = this.get('domainObject');

    // kontroluji jen pokud je model domain
    if (domain && domain.constructor.modelName === 'domain-check') {
      let checkedName = `${name.toLowerCase()}.${domain.get('tldName')}`;
      const lastName = this.get('lastCheckedName');
      if (lastName !== checkedName) {
        // pokud se zmenilo jmeno - reset
        domain.set('state', 'initial');
      }

      const tldLength = domain.get('tld.length');
      const { min, max } = tldLength || {};

      if (name.length < min || name.length > max) {
        domain.set('state', 'taken');
        return;
      }

      this.set('premiumPrice', null);
      this.set('lastCheckedName', checkedName);
      this.get('taskQueue').enqueue('domainCheckTask', domain, checkedName);
    }
  },
  _addToCart() {
    this.get('cart')
      .addItem('registration', this.cartParams())
      .then(item => {
        this.set('cartItemId', item.id);
      });
  },
  _loadPremiumPrice() {
    const domain = this.get('domainObject');
    const name = this.get('name');
    if (!name || this.get('state') !== 'available') {
      return;
    }

    const adapter = this.store.adapterFor('wyApi');
    const urlString = `
    ${
      config.API_HOST
    }/order/priceDomain?domain_name=${name}&toplevel=${domain.get(
      'tldName'
    )}&currency=${this.get(
      'currentUser.currency'
    )}&with_price=1&apply_discounts=1`;

    const url = new URL(urlString);
    return adapter.ajax(addDefaultParams(url.toString()), 'GET').then(data => {
      if (data.success) {
        const { register_customer: price } = data.prices || {};
        this.set('premiumPrice', price);
      }
    });
  },
  actions: {
    toggleSelected() {
      if (this.get('state') === 'available') {
        if (this.get('selected')) {
          this.get('cart').removeItem('registration', this.cartParams());
        } else {
          if (this.get('domainObject.premium')) {
            this.get('modal').show(
              'info/premium-domain',
              {
                originalPrice: this.get('price'),
                premiumPrice: this.get('premiumPrice'),
                domainName: this.get('domainObject.name')
              },
              {
                modalClasses: 'premium-domain',
                addToCartCallback: () => this._addToCart(),
                type: 'domena'
              }
            );
            return;
          }
          this._addToCart();
        }
      }
    },
    nothing() {
      return false;
    }
  }
});
